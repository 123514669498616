export const CA = "0x92F674ca8745Ef98234aFAE69C3aADeE460BB1A5";
export const TELEGRAM = "https://t.me/cumontrump";
export const TWITTER = "https://x.com/cumontrump_eth";

export const BUY_LINK = "";
export const DEXSCREENER = "";
export const RAYDIUM = "";
export const JUPITER = "";
export const DEXTOOLS = "";

export const RPC_ENDPOINT =
    "";
