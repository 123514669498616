import React, { useEffect, useState } from "react";

export const Banner = (props: any) => {
  const [isButtonEnabled, setIsButtonEnabled] = useState(false); // Initialize button state as disabled

  useEffect(() => {
    let timer = setTimeout(() => {
      setIsButtonEnabled(true);
    }, 1 * 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="w-full min-h-screen flex justify-center items-center bg-cover bg-center bg-background " 
      //style={{ backgroundImage: "url('./block1/banner.png')" }}
    >
      <div className="flex flex-col gap-12 justify-center items-center">
        {/* <h1 className="text-4xl text-white font-bold capitalize">AAAAA</h1> */}
        <img 
          src="./logotext.png" 
          alt="logo" 
          className="w-1/2 h-auto "
         />
        <button
          className={`proceed-btn text-xl  text-black font-bold p-4 rounded-xl ${
            !isButtonEnabled
            //</div>  ? "bg-gray-600 text-gray-800 cursor-not-allowed"
            //  : "bg-white"
          }`}
          onClick={() => props.close()}
          disabled={!isButtonEnabled}
          style={{
            backgroundImage: !isButtonEnabled ? "none" : "url('./enter.png')",
            width: '300px', // Buton genişliği
            height: '200px' ,// Buton yüksekliği
            backgroundSize: 'cover',
            backgroundPosition: 'center'  ,
          }}
        >
          <span className="sr-only">Enter </span>
        </button>
      </div>
    </div>
  );
};

