import { MovingImg } from "../../../../common/moving-img";

import { motion } from "framer-motion";
import { BUY_LINK } from "../../../../common/urls";

export const Block2 = () => {
  const isMobile = window.innerWidth < 768;
  return (
    <div className="w-full min-h-screen items-center justify-center flex flex-col relative bg-cover bg-red-300">
      <MovingImg
        customClassWrapper={"absolute top-[50%] md:top-[30%] right-20"}
        tz={5}
        ty={1}
        tx={10}
        customClassImg={"w-[10vh] z-0 inline"}
        imgPath={"./block1/face2.png"}
      />
      <MovingImg
        customClassWrapper={"absolute bottom-[20%] right-[50%] "}
        tz={5}
        ty={7}
        tx={3}
        customClassImg={"w-[10vh] z-0 inline"}
        imgPath={"./block1/face1.png"}
      />
      <MovingImg
        customClassWrapper={"absolute top-[20%] left-[10%]"}
        tz={5}
        ty={3}
        tx={9}
        customClassImg={"w-[10vh] z-0 inline"}
        imgPath={"./block1/face1.png"}
      />
      <div className=" absolute bottom-0 right-0">
        <img
          className="w-[80vh] md:w-[130vh] mr-[-40vh] img-hor z-0"
          src="./block1/trump1.webp"
          alt="trump"
        />
      </div>
      <motion.div
        className={
          "w-11/12 md:w-10/12 max-w-screen-2x min-h-[80vh] flex flex-col p-8 gap-16"
        }
        initial={{
          opacity: 0,
        }}
        whileInView={{
          opacity: 1,
        }}
        transition={{
          duration: 3,
        }}
        viewport={{ once: true }}
      >
        <p className="text-white font-bold text-4xl w-full text-center">
          $CUMTRUMP
        </p>
        <motion.div
          initial={{
            x: isMobile ? 0 : -500,
          }}
          whileInView={{
            x: 0,
          }}
          transition={{
            duration: 1,
          }}
          viewport={{ once: true }}
          className="flex w-full lg:w-1/2 flex-col gap-8 h-full text-xl lg:text-4xl my-auto justify-center items-start"
        >
          <p className="text-left w-full text-white font-bold  z-50">
            &#x2022; Initial dev buy 0%
          </p>
          <p className="text-left text-white font-bold z-50">
            &#x2022; %20 Buy Tax first 20
          </p>
          <p className="text-left w-full text-white font-bold  z-50">
            &#x2022; %20 Sell Tax first 20
          </p>
          <p className="text-left w-full text-white font-bold z-50">
            &#x2022; Tax fees will be used for Dextools
          </p>
        </motion.div>
        <div className="flex flex-col gap-4 w-full items-center">
          <div className="flex md:flex-row flex-col gap-4">
            {/* <button
              onClick={() => {
                window.open("/drop", "_blank");
              }}
              type="button"
              className="z-50 text-white bg-red-500 hover:bg-red-800 hover:text-black focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 "
            >
              <p className="w-full text-center text-xl uppercase">
                Sign up for Airdrop
              </p>
            </button> */}
            <button
              onClick={() => {
                window.open(BUY_LINK, "_blank");
              }}
              type="button"
              className="z-50 text-gray-900 bg-gray-100 hover:bg-gray-200  focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 "
            >
              <p className="w-full text-center text-xl uppercase">BUY</p>
            </button>
          </div>
        </div>
      </motion.div>
    </div>
  );
};
