export const FooterSection = () => {
  return (
    <footer className="px-8 pt-1 text-center bg-[#1f2937] text-white backdrop-blur transition-colors duration-500">
      <p className="flex flex-row items-center justify-center gap-1">
        <span className="text-sm font-bold sm:text-xl ">
          $CUMTRUMP &copy; 2024
        </span>
      </p>
      <p className="px-2 pb-2 text-sm">
        $CUMTRUMP is a memecoin with no intrinsic value, and is not a investment
        and simply a community/culture token similar to $CUMTRUMP. No Promises, No
        Utility, Purely For Entertainment Purposes
      </p>
    </footer>
  );
};
